<template>
  <div class="my">
    <div class="navbar-box">
      <!-- <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">My</div>
        </template>
      </van-nav-bar> -->
    </div>

    <div class="userinfo">
      <van-image width="100%" :src="require('./img/img1.png')" />
      <div class="bg">
        <div
          class="left"
          @click="
            show3 = true;
            loginType = 1;
          "
        ></div>
        <div class="right" @click="to_set"></div>
      </div>
    </div>

    <div class="content">
      <van-image
        width="100%"
        :src="require('./img/img2.png')"
        @click="to_orders"
      />

      <div class="content-bottom">
        <van-image
          width="100%"
          fill="contain"
          :src="require('./img/img3.png')"
        />
        <div class="bg">
          <!-- @click="show = true" -->
          <div class="bg-line" @click="to_vip"></div>
          <div class="bg-line"></div>
          <div class="bg-line" @click="to_address"></div>
          <div class="bg-line"></div>
          <!-- @click="to_qa" -->
          <div class="bg-line"></div>
          <div class="bg-line" @click="show1 = true"></div>
          <div class="bg-line"></div>
          <div class="bg-line"></div>
        </div>
      </div>
    </div>

    <!-- <div class="version">
      <van-image width="21.75rem" :src="require('./img/version.png')" />
    </div> -->

    <Tabbar name="my" />

    <div class="modal-box">
      <van-dialog v-model="show" :showConfirmButton="false" width="36.25rem">
        <div class="dialog-box">
          <van-image width="100%" :src="require('./img/bind.png')" />
          <div class="close" @click="show = false"></div>
          <div class="bg" @click="to_vip"></div>
        </div>
      </van-dialog>

      <van-dialog v-model="show1" :showConfirmButton="false" width="37.5rem">
        <div class="dialog-box">
          <van-image
            width="100%"
            :src="require('../home/img/contact-us.png')"
          />
          <div class="close" @click="show1 = false"></div>
          <div class="bg" @click="show1 = false"></div>
        </div>
      </van-dialog>

      <van-action-sheet v-model="show3">
        <div class="login-content">
          <div class="login1" v-if="loginType === 1">
            <van-image width="100%" :src="require('./img/login1.png')" />
            <div class="bg">
              <div class="bg-1" @click="loginType = 2"></div>
              <div class="bg-2" @click="loginType = 3"></div>
            </div>
          </div>

          <div class="login2" v-if="loginType === 2">
            <van-image width="100%" :src="require('./img/login2.png')" />
            <div class="bg">
              <div class="close" @click="loginType = 1"></div>
            </div>
          </div>

          <div class="login3" v-if="loginType === 3">
            <van-image width="100%" :src="require('./img/login3.png')" />
            <div class="bg">
              <div class="close" @click="loginType = 1"></div>
            </div>
          </div>

          <div class="close" @click="show3 = false"></div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import Tabbar from "@/components/tabbar";
export default {
  components: { Tabbar },
  data() {
    return {
      show: false,
      show1: false,
      show3: false,
      loginType: 1,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    to_orders() {
      this.$router.push({ path: "/orders" });
    },
    to_vip() {
      this.$router.push({ path: "/vip" });
    },
    to_address() {
      this.$router.push({ path: "/address" });
    },
    to_qa() {
      this.$router.push({ path: "/qa" });
    },
    to_set() {
      this.$router.push({ path: "/set" });
    },
  },
};
</script>

<style lang="scss">
.my {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__title {
      overflow: initial;
    }

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .userinfo {
    position: relative;

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;

      .left {
        width: 70%;
        height: 100%;
      }

      .right {
        width: 30%;
        height: 100%;
      }
    }
  }

  .content {
    padding: 0 1.875rem;
    margin-top: -9.375rem;
    position: relative;
    display: flex;
    flex-direction: column;
    .line {
      height: 1px;
      background-color: #777a89;
    }

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .content-bottom {
      margin-top: 1.25rem;
      position: relative;

      .bg {
        padding: 0.625rem 0;

        .bg-line {
          height: calc(100% / 8);
        }
      }
    }
  }

  .version {
    width: 100%;
    // padding-bottom: 2.5rem;
    margin-top: 1.25rem;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    padding-bottom: 6.875rem;
  }

  .dialog-box {
    .close {
      width: 5.25rem;
      height: 5.25rem;
      top: 0;
      right: 0;
      position: absolute;
      z-index: 5;
    }

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.login-content {
  padding-bottom: 6.25rem;
  position: relative;

  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: 5rem;
    height: 5rem;
  }

  .login1 {
    position: relative;

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .bg-1 {
        height: 10rem;
        margin-top: 23.5rem;
      }

      .bg-2 {
        height: 8rem;
        margin-top: 14.5rem;
      }
    }
  }

  .login2 {
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .close {
        position: absolute;
        left: 0;
        top: 0;
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .login3 {
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .close {
        position: absolute;
        left: 0;
        top: 0;
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
</style>